/*
    author          : creenx rodriguez
    date created    : 2023-05-16 1016H
    description     : main stylesheet

*/

@font-face {
    font-family: 'Mark Pro Light';
    src: url('../fonts/MarkPro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Mark Pro';
    src: url('../fonts/Mark-Pro.ttf') format('truetype');
}

@font-face {
    font-family: 'Mark Pro Bold';
    src: url('../fonts/Mark-Pro-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mark Pro Medium';
    src: url('../fonts/MarkPro-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Mark Pro Heavy';
    src: url('../fonts/Mark-Pro-Heavy.ttf') format('truetype');
}

$slick-font-family:FontAwesome;
$slick-prev-character: "\f053";
$slick-next-character: "\f054";

$slide-count: 5;
$slide-width: 20rem;

$black: #000000;
$grey: #7c7e81;
$dark-grey: #292c2d;
$primary: #eb001b;
$midcolor: #ff5f00;
$secondary: #f79e1b;
$baby-blue: #66b4fc;

:root {
    --up: 5;
    --card-margin: 12px;
    --card-shadow: rgba(49,49,52,.4);
    --cardWidth: calc(96vw/var(--up));
    --carouselWidth: calc(var(--cardWidth) * var(--num));
    --carouselLeftMargin: calc(2vw - var(--cardWidth));
    --color-white: #ffffff;
    --color-ui-hover: #666;
    --img-path: '../img/const-trading.png';

    @media screen and (max-width: 900px) { /*basically mobile*/
        --up: 1;
    }
}

html {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    scroll-behavior: smooth!important;
   
}

body {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    font-family: 'Mark Pro Light';
    background-color: #000000;
}

h1 {
    font-family: 'Mark Pro Bold';
}

p {
    font-family: 'Mark Pro';
    font-size: 1em;
}

.boxed {
    border-radius: 0!important;
    margin: 5px 0;
    font-size: 0.8rem;
   
}


.btn-orange {
    border: 0 !important;
    border-radius: 0 !important;
    background-color: #000000;
    padding: 10px 50px;
    font-size: 1rem;
    color: #ffffff;

    &:hover {
        background-color: $primary;
        color: #ffffff;
    }
}

.active-scroll-spy {
    border-bottom: 1px solid #ff5f00;
    
    a {
        color: #ff5f00;
    }
}



.slick-arrow {
    width: 60px!important;
    height: 60px!important;
    z-index: 10000!important;
    
}

.slick-prev, .slick-next {

    

    &:before {
        font-size: 3rem!important;
    }
}


.main-wrapper {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
    min-height: 100vmin !important;
    background-color: #ffffff;

    .side-widget {
        position: fixed;
        width: auto !important;
        height: auto;
        display: flex;
        flex-direction: column;
        right: 30px;
        bottom: 100px !important;
        z-index: 999999 !important;
        padding: 10px;

        .btn-social {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
            border-radius: 50%;
            overflow: hidden;
            display: block;
            float: right !important;
            text-shadow: 1px 1px 1 #ffffff,
                2px 1px 1 #ffffff,
                1px 2px 1 #ffffff,
                2px 2px 1 #ffffff;
            
            transition: all 2s ease-in-out;

            img {
                display: block;
                width: 100%;
                margin: 0 auto !important;

            }

            &--facebook {
                // background-color: #1877f2;
                background-color: #7b8492;
                
            }

            &--insta {
                // background-color: #292c2d;
                background-color: #7b8492;
                
            }

            &--linkedin {
                // background-color: #007bb5;
                background-color: #7b8492;
                
            }

            &--twitter {
                // background-color: #1d9bf0;
                background-color: #7b8492;
                transition: 1.5s ease!important;
            }

            &--whatsapp {
                // background-color: #0d9f16;
                background-color: #7b8492;
                
            }

            &--email {
                // background-color: #00abe9;
                background-color: #7b8492;
                
            }

            &--ytube {
                // background-color: $grey;
                background-color: #7b8492;
                
            }

            &:hover {
                transform: scale(1.8);
                background-color: $primary;
                box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
            }
        }

    }

    .header-wrapper {
        position: fixed;
        top: 0px !important;
        left: 0px !important;
        width: 100%;
        height: 100px !important;
        z-index: 999999;

        .header-container {
            position: absolute;
            top: 0px !important;
            left: 0px !important;
            width: 100%;
            height: 100px !important;
            background-color: #292c2d;
            opacity: 0.8;
        }

        .navigation {
            position: relative;
            width: 100% !important;
            height: 100% !important;
            padding: 30px 5% !important;
            // display: flex;
            background-color: transparent;
            color: #ffffff;
            align-items: center;

            .brand-name {
                text-decoration: none;

                .header-logo {
                    height: 50px !important;
                }
            }

            .hamburger {
                border: 0px !important;
                height: 30px;
                width: 30px;
                padding: 0.5rem;
                background-color: transparent;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                position: absolute;
                top: 30%;
                right: 25px;
                transform: translate(-50%);
                display: none;

               
            }


            .navigation-menu {
                padding: 10px 0!important;
                float:right;
               
                ul {
                    display: flex;
                    justify-content: space-between;
                    padding: 0;
                    list-style-type: none;
                }

                li {
                    margin: 0 1rem;
                    border-bottom: 1px solid transparent;
                    min-width: 100px;
                    text-align: center;
                    height: 30px;
                    transition: border-bottom 0.8s ease-in-out;

                    a {
                        text-decoration: none;
                        color: #ffffff;
                        display: block;
                        width: 100%;
                        height: 30px;
                        transition: color 0.8s ease-in-out;

                        &:hover {
                            color: #ff5f00;
                        }

                        .active {
                            color: #ff5f00;
                        }
                        
                    }

                    

                    &:hover {
                        border-bottom: 1px solid #ff5f00;
                    }

                    &:not(:last-child) {
                        margin-right: 60px;
                    }
                }
            }



        }
    }

    .content-wrapper {
        width: 100%;
        padding: 0px !important;
        margin: 0px !important;
        height: auto !important;
        min-height: 100vmin;

        .page-wrapper {
            position: relative;
            margin: 0px !important;
            width: 100%;
            height: auto;
            min-height: 600px !important;

            .banner-page {
                position: relative;
                top: 0px !important;
                width: 100%;
                margin: 0 !important;
                height: 100vmin;
                min-height: 100vmin;
                padding: 0 !important;
                background-color: #000000;

                .banner-background {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    min-height: 100vmin;
                    background-image: url('../img/banner-bg.jpeg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: bottom;
                    opacity: 0.7;
                }

                .banner-container {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    min-height: 100vmin;
                    margin: 0 !important;
                    z-index: 888888;

                    .banner-content-text {
                        position: absolute;
                        top: 50%;
                        left: 5%;
                        width: 50%;
                        transform: translateY(-50%);
                        text-align: left;
                        height: auto!important;

                        h1 {
                            font-family: 'Mark Pro Bold';
                            color: #ffffff;
                            font-size: 6rem;
                            line-height: 3.5rem;
                        }

                        span {
                            font-family: 'Mark Pro Light';
                            color: #ffffff;
                            font-size: 2rem;
                            letter-spacing: 5px;
                            display: block;
                            margin-bottom: 30px;
                            line-height: 2rem;
                        }
                    }
                }


            }

            .title-block {
                width: 100%;
                padding: 30px 5% !important;
                margin-bottom: 50px;
                text-align: left;

                .grey-span {
                    display: block;
                    font-family: 'Mark Pro Light';
                    font-size: 1.2em;
                    letter-spacing: 1px;
                }

                .wexford-secondary {
                    display: block;
                    font-family: 'Mark Pro Medium';
                    font-size: 1.5em;
                    color: #292c2d;
                    letter-spacing: 0px;
                }

                .wexford {
                    font-family: 'Mark Pro Bold';
                    font-size: 3.5rem;
                    color: #292c2d;
                    letter-spacing: 0px;
                    line-height: 1.2rem;

                    .red-dot {
                        color: #eb001b;
                        font-style: normal !important;
                    }
                }

                .span-title {
                    font-family: 'Mark Pro Bold';
                    font-size: 3.5rem;
                    color: #292c2d;
                    letter-spacing: 0px;
                    line-height: 2.5rem;

                    .red-dot {
                        color: #eb001b;
                        font-style: normal !important;
                    }
                }

            }

            .title-center {
                width: 100%;
                padding: 30px 5% !important;
                margin-bottom: 40px;
                text-align: center;

                .title-secondary-dark {
                    display: block;
                    font-family: 'Mark Pro Light';
                    font-size: 1.5em;
                    color: #ffffff;
                    letter-spacing: 0px;
                }

                .title-secondary-light {
                    display: block;
                    font-family: 'Mark Pro Light';
                    font-size: 1.5em;
                    color: #292c2d;
                    letter-spacing: 0px;
                }

                .span-title {
                    font-family: 'Mark Pro Bold';
                    font-size: 3.5rem;
                    color: #292c2d;
                    letter-spacing: 0px;
                    line-height: 2.5rem;

                    .red-dot {
                        color: #eb001b;
                        font-style: normal !important;
                    }
                }
            }

            .page-content {
                width: 100%;
                height: auto;
                min-height: 200px;

                .content-full {
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;
                }

                .content-padded {
                    width: 100%;
                    height: auto;
                    padding-left: 5%;
                    padding-right: 5%;
                    margin: 0 !important;
                }


            }

            .dark-page {
                position: relative;
                background-color: #292c2d;
                width: 100%;
                padding-top: 100px;
                padding-bottom: 60px;
                margin: 0 !important;
                min-height: 600px !important;

                .title-block {
                    width: 100%;
                    padding: 30px 5% !important;
                    margin-bottom: 40px;

                    .white-span {
                        display: block;
                        font-family: 'Mark Pro Light';
                        color: #ffffff;
                        font-size: 1.2em;
                        letter-spacing: 1px;
                    }

                    .span-title {
                        font-family: 'Mark Pro Bold';
                        font-size: 3.5rem;
                        color: #ffffff;
                        letter-spacing: 0px;
                        line-height: 2.5rem;

                        .red-dot {
                            color: #eb001b;
                            font-style: normal !important;
                        }
                    }

                }


            }

            .addon-page-light {
                position: relative;
                width: 100%;
                padding-top: 30px;
                margin: 0 !important;
                background-color: #ffffff;
                padding-bottom: 40px;

            }

            .addon-page-dark {
                position: relative;
                width: 100%;
                padding-top: 30px;
                margin: 0 !important;
                background-color: #292c2d;
                padding-bottom: 40px;
            }

            .pages {
                position: relative;
                width: 100%;
                padding-top: 100px;
                margin: 0 !important;
                padding-bottom: 60px;


            }
        }

        .page-about {
           
            .pages {

                .about-bg {
                    // background-image: url('../img/about-002.png');
                    background-position: left;
                    background-size: cover;
                    background-repeat: no-repeat;
                    height: auto;
                    min-height: 300px;

                    p {
                        text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
                    }

                    .slogan {
                        padding-left: 15%;
                        width: 100%;

                        h2 {
                            font-family: 'Mark Pro Bold';
                            font-size: 4rem;
                            color: #eb001b;
                            line-height: 1rem;
                        }

                        h3 {
                            font-family: 'Mark Pro Medium';
                            font-size: 3rem;
                            color: #292c2d;
                        }
                    }

                    
                    .card-about {
                        position: relative;
                        width: 250px;
                        height: 375px;
                        background-color: #ffffff;
                        margin: 0 auto !important;
                        cursor: pointer;
                        

                        img {
                            width: 100%;
                            display: block;
                            height: auto;
                        }

                        .primary-content {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            align-items: center;
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            padding: 20px !important;
                            background-color: #292c2d;
                            opacity: 0;
                            height: 100%;
                            width: 100%;
                            transition: 0.5s ease;
                            text-align: center;
                            align-items: center;

                            .icons {
                                width: 60px;
                                height: 60px;
                                display: block;
                                margin: 0 auto;
                            }

                            .title {
                                display: block;
                                font-family: 'Mark Pro Bold';
                                font-size: 0.9rem;
                                color: #ff5f00;
                                text-align: center;
                                line-height: 1.5rem;
                                margin-bottom: 20px;
                            }

                            .subtitle {
                                display: block;
                                font-family: 'Mark Pro Bold';
                                font-size: 0.7rem;
                                color: #ffffff;
                                text-align: left;
                            }

                            .desc {
                                font-family: 'Mark Pro';
                                font-size: 0.7rem;
                                color: #ffffff;
                                text-align: left;
                                text-shadow: none;
                            }

                            .btn-read-more {
                                border: 0 !important;
                                border-radius: 0 !important;
                                background-color: #000000;
                                padding: 10px 30px;
                                font-size: 0.8rem;
                                color: #ffffff;

                                &:hover {
                                    background-color: #ff5f00;
                                    color: #ffffff;
                                }
                            }
                        }

                        &:hover {
                            box-shadow: 0 0 15px rgba(black, 0.9);
                            .overlay {
                                opacity: 0.9;
                            }
                        }


                    }
                }

                .statement-section {
                    width: 100%;
                    height: auto!important;
                    min-height: 400px;
                    margin: 0!important;

                    .mission {
                        width: 100%!important;
                        height: 400px;
                        max-height: 400px;
                        display: flex;
                        flex-direction: row;
                        background-color: $black;
                        padding: 0!important;
                        
                        .item-left {
                            position: relative;
                            width: auto;
                            height: 400px;
                            flex-grow: 1;
                            padding: 40px;
                            overflow: hidden;

                            .img-holder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 400px;
                                background-image: url('../img/mission-left.jpg');
                                background-size: cover;
                                background-position: center;
                                transition: 1s ease-in-out;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                background-color: $black;
                                opacity: 0.6;
                                height: 400px!important;
                                transition: 1s ease-in-out;
                                margin: 0;
                                padding: 0;
                            }

                            &:hover {
                                .img-holder {
                                    transform: scale(1.5);
                                }
                                
                                .overlay {
                                    opacity: 0;
                                }

                            }
                        }

                        .item-center {
                            position: relative;
                            flex-grow: 1;
                            height: 400px;
                            padding: 40px;
                            align-items: center;
                            overflow: hidden;
                            cursor: pointer;
                            
                            

                            .img-mission {
                                position: absolute;
                                width: 150px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }

                            .overlay {
                                position: absolute;
                                width: 100%;
                                height: 400px;
                                padding: 40px;
                                top: 0;
                                left: 0;
                                opacity: 1;
                                background-color: $primary;
                                transition: 1.5s ease-in-out;

                                .desc-container {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 80%;
                                    height: 80%;
                                    transform: translate(-50%,-50%);

                                    .img-overlay {
                                        display: block;
                                        margin: 0 auto !important;
                                        width: 60px;
                                        
                                    }

                                    h5 {
                                        font-family: 'Mark Pro Bold';
                                        text-align: center;
                                        font-size: 2rem;
                                        font-weight: 300;
                                        color: #ffffff;

                                    }

                                    .desc {
                                        font-family: 'Mark Pro';
                                        font-size: 1rem;
                                        color: #ffffff;
                                        text-align: left;
                                        text-shadow: none;
                                    }
    
                                    .btn-read-more {
                                        display: block;
                                        border: 0 !important;
                                        border-radius: 0 !important;
                                        background-color: #000000;
                                        padding: 10px 30px;
                                        font-size: 0.7rem;
                                        width: auto;
                                        color: #ffffff;
                                        margin: 0 auto!important;
                                        transition: 1s ease;
                                        width: 30%;
                                        &:hover {
                                            background-color: #ff5f00;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .overlay {
                                    opacity: 0;
                                }

                                .img-mission {
                                    opacity: 1;
                                }
                            }

                        }

                        .item-right {
                            position: relative;
                            flex-grow: 1;
                            width: auto;
                            height: 400px;
                            padding: 40px;
                            overflow: hidden;

                            .img-holder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 400px;
                                background-image: url('../img/mission-right.jpg');
                                background-size: cover;
                                background-position: center;
                                transition: 1s ease-in-out;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                background-color: $black;
                                opacity: 0.6;
                                height: 400px!important;
                                transition: 1s ease-in-out;
                                margin: 0;
                                padding: 0;
                            }

                            &:hover {
                                .img-holder {
                                    transform: scale(1.5);
                                }
                                
                                .overlay {
                                    opacity: 0;
                                }

                            }
                        }

                    }

                    .values {
                        width: 100%!important;
                        height: 400px;
                        max-height: 400px;
                        display: flex;
                        flex-direction: row;
                        background-color: $black;
                        padding: 0!important;
                        overflow: hidden;
                        cursor: pointer;

                       

                        .item-left {
                            position: relative;
                            flex-grow: 1;
                            width: auto;
                            height: 400px;
                            padding: 40px;
                            overflow: hidden;
                            cursor: pointer;

                            .img-holder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 400px;
                                background-image: url('../img/cityscape.jpg');
                                background-size: cover;
                                background-position: center;
                                transition: 1s ease-in-out;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                background-color: $black;
                                opacity: 0.8;
                                height: 400px!important;
                                transition: 1s ease-in-out;
                                margin: 0;
                                padding: 0;
                            }

                            &:hover {
                                .img-holder {
                                    transform: scale(1.5);
                                }
                                
                                .overlay {
                                    opacity: 0;
                                }

                            }
                        }

                        .item-center {
                            position: relative;
                            flex-grow: 1;
                            height: 400px;
                            padding: 40px;
                            align-items: center;
                            overflow: hidden;
                            cursor: pointer;

                            .img-values {
                                position: absolute;
                                width: 150px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }

                            .overlay {
                                position: absolute;
                                width: 100%;
                                height: 400px;
                                padding: 40px;
                                top: 0;
                                left: 0;
                                opacity: 1;
                                background-color: $midcolor;
                                transition: 1.5s ease-in-out;

                                .desc-container {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 80%;
                                    height: auto;
                                    transform: translate(-50%,-50%);

                                    .img-overlay {
                                        display: block;
                                        margin: 0 auto !important;
                                        width: 60px;
                                        
                                    }

                                    h5 {
                                        font-family: 'Mark Pro Bold';
                                        text-align: center;
                                        font-size: 2rem;
                                        font-weight: 300;
                                        color: #ffffff;

                                    }

                                    ul {
                                        li {
                                            color: #ffffff;
                                        }
                                    }
    
                                    .btn-read-more {
                                        display: block;
                                        border: 0 !important;
                                        border-radius: 0 !important;
                                        background-color: #000000;
                                        padding: 10px 30px;
                                        font-size: 0.7rem;
                                        width: auto;
                                        color: #ffffff;
                                        margin: 0 auto!important;
                                        transition: 1s ease;
                                        width: 30%;
                                        &:hover {
                                            background-color: $primary;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .overlay {
                                    opacity: 0;
                                }

                                .img-values {
                                    opacity: 1;
                                }
                            }


                        }

                        .item-right {
                            position: relative;
                            flex-grow: 1;
                            width: auto;
                            height: 400px;
                            padding: 40px;
                            overflow: hidden;
                            cursor: pointer;

                            .img-holder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 400px;
                                background-image: url('../img/water-front.png');
                                background-size: cover;
                                background-position: center;
                                transition: 1s ease-in-out;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                background-color: $black;
                                opacity: 0.8;
                                height: 400px!important;
                                transition: 1s ease-in-out;
                                margin: 0;
                                padding: 0;
                            }

                            &:hover {
                                .img-holder {
                                    transform: scale(1.5);
                                }
                                
                                .overlay {
                                    opacity: 0;
                                }

                            }
                        }
                    }

                    .vision {
                        width: 100%!important;
                        height: 400px;
                        max-height: 400px;
                        display: flex;
                        flex-direction: row;
                        background-color: $black;
                        padding: 0!important;

                        .item-left {
                            position: relative;
                            width: auto;
                            height: 400px;
                            flex-grow: 1;
                            padding: 40px;
                            overflow: hidden;

                            .img-holder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 400px;
                                background-image: url('../img/building.png');
                                background-size: cover;
                                background-position: center;
                                transition: 1s ease-in-out;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                background-color: $black;
                                opacity: 0.6;
                                height: 400px!important;
                                transition: 1.5s ease-in-out;
                                margin: 0;
                                padding: 0;
                            }

                            &:hover {
                                .img-holder {
                                    transform: scale(1.5);
                                }
                                
                                .overlay {
                                    opacity: 0;
                                }

                            }
                        }

                        .item-center {
                            position: relative;
                            flex-grow: 1;
                            height: 400px;
                            padding: 40px;
                            align-items: center;
                            overflow: hidden;
                            cursor: pointer;
                            
                            .img-vision {
                                position: absolute;
                                width: 150px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }

                            .overlay {
                                position: absolute;
                                width: 100%;
                                height: 400px;
                                padding: 40px;
                                top: 0;
                                left: 0;
                                opacity: 1;
                                background-color: $secondary;
                                transition: 1.5s ease-in-out;

                                .desc-container {
                                    position: absolute;
                                    width: 80%;
                                    height: auto;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%,-50%);

                                    .img-overlay {
                                        display: block;
                                        margin: 0 auto !important;
                                        width: 60px;
                                        
                                    }

                                    h5 {
                                        font-family: 'Mark Pro Bold';
                                        text-align: center;
                                        font-size: 2rem;
                                        font-weight: 300;
                                        color: #ffffff;

                                    }

                                    .desc {
                                        font-family: 'Mark Pro';
                                        font-size: 1rem;
                                        color: #ffffff;
                                        text-align: left;
                                        text-shadow: none;
                                    }
        
                                    .btn-read-more {
                                        display: block;
                                        border: 0 !important;
                                        border-radius: 0 !important;
                                        background-color: #000000;
                                        padding: 10px 30px;
                                        font-size: 0.7rem;
                                        width: auto;
                                        color: #ffffff;
                                        margin: 0 auto!important;
                                        transition: 1s ease;
                                        width: 30%;
                                        &:hover {
                                            background-color: #ff5f00;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .overlay {
                                    opacity: 0;
                                }

                                .img-vision {
                                    opacity: 1;
                                }
                            }
                        }

                        .item-right {
                            position: relative;
                            width: auto;
                            height: 400px;
                            flex-grow: 1;
                            padding: 40px;
                            overflow: hidden;

                            .img-holder {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 400px;
                                background-image: url('../img/vision-right.png');
                                background-size: cover;
                                background-position: center;
                                transition: 1s ease-in-out;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                background-color: $black;
                                opacity: 0.6;
                                height: 400px!important;
                                transition: 1.5s ease-in-out;
                                margin: 0;
                                padding: 0;
                            }

                            &:hover {
                                .img-holder {
                                    transform: scale(1.5);
                                }
                                
                                .overlay {
                                    opacity: 0;
                                }

                            }
                        }
                    }
                    

                }

            }
        }

        .page-services {
            // background-image: url('../img/services-bg.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .pages {

                p {
                    margin-top: 50px;
                }


                .service-content {
                    width: 100%;
                    padding: 40px 5% !important;
                    height: auto;
                    min-height: 600px;

                    .service-category-title {
                        display: block;
                        color: #292c2d;
                        font-size: 1.5rem;
                        font-family: 'Mark Pro';
                        margin: 20px 5% !important;


                    }

                    .service-card {
                        position: relative;
                        width: 300px;
                        height: 300px;
                        background-color: #292c2d;
                        margin: 30px auto !important;
                        transition: all 1s ease;
                        overflow: hidden;
                        cursor: pointer;

                        img {
                          
                            width: 100%;
                            transition: 1s ease-in-out;
                        }

                        .title-bar {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 50px;
                            padding: 10px 50px 10px 20px;
                            background-color: #292c2d;
                            opacity: 1;
                            transition: 1s ease-in-out;

                            .title {
                                display: block;
                                color: #ffffff;
                                font-size: 0.8rem;
                                line-height: 0.8rem;
                                text-transform: uppercase;

                            }

                            .title-icon {
                                position: absolute;
                                width: 50px;
                                height: 50px;
                                right: 0;
                                bottom: 0;
                                
                                background-color: $primary;

                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 80%!important;
                                    transform: translate(-50%, -50%);
                                }

                            }
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            padding: 30px !important;
                            background-color: #292c2d;
                            opacity: 0;
                            height: 100%;
                            width: 100%;
                            transition: 0.5s ease;
                            text-align: center;
                            align-items: center;

                            img {
                                display: block;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                padding: 10px;
                                background-color: $primary;
                                margin: 20px auto !important;
                            }

                            .title-big {
                                color: #ffffff;
                                font-family: 'Mark Pro';
                                padding-top: 50px;
                                font-size: 1.2rem;
                                line-height: 1rem;
                                text-align: center;
                                text-transform: uppercase;
                            }

                        }

                        &:hover {
                            box-shadow: 0 0 15px rgba(black, 0.9);

                            img {
                                transform: scale(1.2);
                            }
                            .overlay {
                                opacity: 0.9;
                            }

                            .title-bar {
                                opacity: 0;
                            }
                        }
                    }
                }

            }
        }

        .page-projects {

            .dark-page {

                .project-headers {
                    display: block;
                    margin-left: 5%;
                    color: #ffffff;
                    font-size: 1.2rem;
                    font-family: 'Mark Pro Medium';
                    text-transform: uppercase;
                }

                .project-container {
                    position: relative;
                    width: 100%;
                    min-height: 460px;
                    height: auto;
                    padding: 30px 5%!important;

                    .card {
                        height: 400px;
                        width: 400px;
                        cursor: pointer;
                        background-color: $black;
                        border: 0;
                        display: block;
                        left: 50%;
                        transform: translateX(-50%);
                        box-shadow: 0 0 5px rgba(black, 0.3);
                        overflow: hidden;

                        .title-bar {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 40px;
                            text-align: center;
                            text-transform: uppercase;
                            opacity: 1;
                            background-color: $black;

                            .title {
                                position: absolute;
                                width: 90%!important;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: var(--color-white);
                                text-align: center;
                                display: block;
                                font-size: 0.8rem!important;

                            }
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            width: 400px;
                            height: 400px;
                            background-color: $black;
                            opacity: 0;
                            transition: all 0.5s ease-in-out;

                            .desc-container {
                                position: absolute;
                                width: 80%;
                                height: auto;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%,-50%);

                                .title {
                                    font-family: 'Mark Pro Medium';
                                    font-size: 1.2rem;
                                    color: var(--color-white);
                                    display: block;
                                    text-align: center;
                                    text-transform: uppercase;
                                    margin-bottom: 40px;
                                }
                                .desc {
                                    color: var(--color-white);
                                    display: block;
                                    font-family: 'Mark Pro';
                                    font-size: 0.9rem;
                                    text-align: center;
                                }
                                
                            }
                        }

                        .img-container {
                            position: absolute;
                            width: 400px;
                            height: 400px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: 1s ease-in-out;
                        }

                        &:hover {

                            .img-container {
                                transform: scale(1.2);
                            }

                            .title-bar{ 
                                opacity: 0;
                            }

                            .overlay {
                                opacity: 0.9;
                            }

                        }
                    }
                }

              
            }

            .addon-page-light {

                .counter-stats {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 50px;
                    cursor: pointer;

                    .stats {
                        text-align: center;
                        width: 180px;
                        height: auto;
                        padding: 20px;
                        margin: 10px;

                        img {
                            display: block;
                            height: 60px;
                            margin: 10px auto !important;
                        }

                        .counting {
                            font-family: 'Mark Pro Bold';
                            font-size: 4rem;
                            font-weight: 300;
                            color:#eb001b;
                            line-height: 5rem;
                        }

                        h5 {
                            font-family: 'Mark Pro';
                            font-size: 1rem;
                            font-weight: 200;
                            line-height: 1rem;
                        }

                        &:hover {
                            border-radius: 15px;
                            box-shadow: 0 0 15px rgba(black, 0.3);
                        }
                    }

                }

                .project-section {
                    width: 100%;
                    height: 500px;
                    
                    .card {
                        height: 400px;
                        width: 400px;
                        background-color: $black;
                        cursor: pointer;
                        border: 0;
                        display: block;
                        left: 50%;
                        transform: translateX(-50%);
                        box-shadow: 0 0 5px rgba(black, 0.3);
                        overflow: hidden;

                        .title-bar {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 40px;
                            text-align: center;
                            text-transform: uppercase;
                            opacity: 0.8;

                            .title {
                                color: $primary;
                                text-align: center;
                                display: block;
                            }
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            background-color: $black;
                            opacity: 0;
                            transition: all 0.5s ease-in-out;

                            .desc-container {
                                position: absolute;
                                width: 80%;
                                height: auto;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%,-50%);

                                .desc {
                                    color: var(--color-white);
                                    display: block;
                                }
                                
                            }
                        }

                        .img-container {
                            position: absolute;
                            width: 100%;
                            height: 400px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: 1s ease-in-out;
                        }

                        &:hover {

                            .img-container {
                                transform: scale(1.2);
                            }

                            .title-bar{ 
                                opacity: 0;
                            }

                            .overlay {
                                opacity: 1;
                            }

                        }
                    }
                }

                .partners-section {
                    width: 100%;
                    height: 300px;
                    padding: 30px 5%;

                   
                    .slick-prev, .slick-next {
                        transition: all 1s ease-in-out;

                        &::before {
                            font-size: 2rem!important;
                            color: #8e99b0!important;
                            opacity: 0.8;
                            -moz-font-smoothing: antialiased!important;
                            -webkit-font-smoothing: antialiased!important;
                        }

                        &:hover {
                            opacity: 0.9;
                        }
                    }
                                .card {
                                    width: 200px;
                                    height: 200px;
                                    background-color: transparent;
                                    cursor: pointer;
                                    border: 0px!important;
                                    display: block;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background-color: #f7f7f7;
                                    box-shadow: 0 0 5px rgba(black, 0.3);

                                    img {
                                        width: 200px;
                                        height: 200px;
                                    }
                                }
                            
                }
               
            }
        }

        .page-contacts {
            position: relative;
            width: 100%;
            height: auto;
            max-height: 820px;
            margin: 0 !important;
        
            .pages {
                position: relative;
                min-height: 800px;
                margin: 0 !important;

                .contacts-bg {
                    width: 100%;
                    height: auto;
                    min-height: 400px;
                    background-image: url('../img/contact-bg.jpg');
                    background-repeat: no-repeat;
                    background-size: cover;

                    .title-block {
                        width: 100%;
                        height: auto;

                        .subheading {
                            display: block;
                            color: #ffffff;
                            font-family: 'Mark Pro';
                            font-size: 1.5rem;
                        }

                        .title {
                            font-family: 'Mark Pro Bold';
                            font-size: 3rem;
                            color:#ffffff;
                            line-height: 2.5rem;

                            .red-dot {
                                color: #eb001b;
                                font-style: normal !important;
                            }
                        }
                    }
                }

                .map-container {
                    position: absolute;
                    top: 250px;
                    width: 60%;
                    height: 450px;
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 20px;
                    background-color: #ffffff;
                    box-shadow: 0 0 20px rgba(black, 0.3);

                    .contact-header {
                        font-family: 'Mark Pro Medium';
                        font-size: 1.5rem;
                        font-weight: 300;
                        display: block;
                        padding-bottom: 20px;
                        text-transform: uppercase;
                    }

                    .map {
                        width: 100%!important;
                        height: 100%!important;
                    }
                }
            }

        }

        .page-team {
            position: relative;

            .pages {

                .team-contents {
                    width: 100%;
                    margin: 0;
                    height: auto;
                    min-height: 600px;

                    .card {
                        position: relative;
                        display: block;
                        width: 400px;
                        height: 600px;
                        background-color: var(--color-white);
                        overflow: hidden;

                        .card-img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 80%;
                        }

                    }
                }
            }
        }



    }

    .footer-wrapper {
        position: relative;
        width: 100%;
        margin: 0 !important;
        height: auto;
        bottom: 0!important;
       

        .address-container {
            width: 100%;
            padding: 50px auto !important;
            background-color: #ffffff;
            .address-header {
                display: block !important;
                font-family: 'Mark Pro';
                font-size: 1.2rem;
                color: #eb001b !important;
            }
        }

        .socmed-container {
            width: 100%;
            align-items: center;
            text-align: center;
            padding: 50px auto!important;
            background-color: #ffffff;

            .foot-title {
                font-size: 1rem;
                color:#292c2d;
                font-weight: 200;
                text-align: center;
                display: block;
                padding: 10px;
            }

            .socmed-holder {
                align-items: center;
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                margin-bottom: 50px;
                .icons {
                    width: 30px;
                    height: 30px;
                    margin: 10px;
                    border-radius: 50%;
                    background-color: #292c2d;
                    text-decoration: none;
                    transition: all 0.5s ease;

                    img {
                        display: block;
                        width: 100%;
                    }

                    &:hover {
                        background-color: $primary;
                    }
                }

            }

        }

        .foot-note {
            width: 100%;
            height: auto;
            padding: 10px 5%;
            background-color: #000000;
            display: flex;
            flex-basis: 20px;
            flex-direction: row;
            color: #ffffff;
            margin: 0 !important;
            

            .foot-logo {
                height: 20px!important;
                padding-right: 20px;
                
            }

            .company-name {
                font-family: 'Mark Pro';
                font-size: 0.8rem;
                color: #ffffff;
                font-weight: 100;
                letter-spacing: 2px;
                display: block;
                width: 80%;

            }

            .policy {
                width: auto!important;
                float: right!important;
                height: 30px;
                

                a {
                    
                    font-weight: 100;
                    font-size: 0.7rem;
                    letter-spacing: 1px;
                    color: #ffffff;
                    text-decoration: none;
                    cursor: pointer;

                    &:hover {
                        color:#ff5f00;
                    }
                }
            }


        }

    }
}


@media screen and (max-width: 900px) {

    .slick-prev, .slick-next {

        &::before {
            font-size: 2rem!important;
        }
    
       
    }

    .main-wrapper {

        .side-widget {
            bottom: 10px;
            right: 10px;

            .btn-social {
                width: 30px;
                height: 30px;
                margin-bottom: 10px;
            }
        }

        .header-wrapper {
            max-width: 100%;
            height: 60px !important;

            .header-container {
                height: 60px !important;
                opacity: 1;
            }

            .navigation {
                padding-top: 20px!important;

                .brand-name {
                    
                    .header-logo {
                        height: 30px !important;
                    }
                }

                .hamburger {
                    right: 5px !important;
                    height: 30px !important;
                    padding: 0 !important;
                    display: block;
                }

                .navigation-menu {

                    position: absolute;
                    right: 0;
                    top: 60px;
                    background-color: #292c2d;
                    width: 0px;
                    height: calc(100vh - 60px);
                    transition: all 0.3s ease-in;
                    overflow: hidden;

                    

                    ul {
                        display: flex;
                        flex-direction: column;
                    }

                    li {
                        text-align: left;
                        margin-right: unset;
                        margin-top: 22px;

                        a {
                            width: 100%;
                            padding: 1.5rem 10px;
                        }

                        &:hover {
                            border: 0 !important;
                        }
                    }



                }

                .active {
                    width: 100%;
                }


            }
        }

        .content-wrapper {

            margin-top: 60px !important;

            .page-wrapper {
                position: relative;
                margin: 0px !important;
                width: 100%;
                height: auto;
                min-height: 400px !important;

                .banner-page {
                    position: relative;
                    top: 0px !important;
                    width: 100%;
                    margin: 0 !important;
                    height: 100vmin;
                    min-height: 100vmin;
                    padding: 0 !important;
                    background-color: #000000;

                    .banner-background {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        min-height: 100vmin !important;
                        background-image: url('../img/banner-bg.jpeg');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: bottom;
                        opacity: 0.7;
                    }

                    .banner-container {

                        .banner-content-text {
                            position: absolute;
                            top: 50%;
                            left: 5%;
                            width: 90%;
                            transform: translateY(-50%);
                            text-align: center;

                            h1 {
                                font-family: 'Mark Pro Bold';
                                color: #ffffff;
                                font-size: 3rem;
                                line-height: 1rem;
                            }

                            span {
                                font-family: 'Mark Pro Light';
                                color: #ffffff;
                                font-size: 1rem;
                                letter-spacing: 2px;
                                display: block;
                                margin-bottom: 30px;
                            }

                            .btn-orange {
                                padding: 10px 30px;
                                font-size: 1rem;
                            }
                        }
                    }


                }

                .dark-page {
                    position: relative;
                    background-color: #292c2d;
                    width: 100%;
                    padding-top: 60px;
                    padding-bottom: 40px;
                    margin: 0 !important;
                    min-height: 400px !important;

                    .title-block {
                        width: 100%;
                        padding: 20px 5% !important;
                        margin-bottom: 30px;

                        .white-span {
                            display: block;
                            font-family: 'Mark Pro Light';
                            color: #ffffff;
                            font-size: 0.9em;
                            letter-spacing: 0px;
                        }

                        .span-title {
                            font-family: 'Mark Pro Bold';
                            font-size: 2rem;
                            color: #ffffff;
                            letter-spacing: 0px;
                            line-height: 2rem;

                            .red-dot {
                                color: #eb001b;
                                font-style: normal !important;
                            }
                        }

                    }


                }

                .addon-page-light {
                    position: relative;
                    width: 100%;
                    padding-top: 30px;
                    margin: 0 !important;
                    background-color: #ffffff;
                    padding-bottom: 40px;

                }

                .addon-page-dark {
                    position: relative;
                    width: 100%;
                    padding-top: 30px;
                    margin: 0 !important;
                    background-color: #292c2d;
                    padding-bottom: 40px;
                }


                .pages {
                    position: relative;
                    width: 100%;
                    padding-top: 60px;
                    margin: 0 !important;

                }

                .title-block {
                    width: 100%;
                    padding: 10px 5% !important;
                    margin-bottom: 40px;
                    text-align: left;

                    .grey-span {
                        display: block;
                        font-family: 'Mark Pro Light';
                        font-size: 1em;
                        letter-spacing: 2px;
                    }

                    .wexford-secondary {
                        display: block;
                        font-family: 'Mark Pro Medium';
                        font-size: 1em;
                        color: #292c2d;
                        letter-spacing: 0px;
                    }

                    .wexford {
                        font-family: 'Mark Pro Bold';
                        font-size: 2.5rem;
                        color: #292c2d;
                        letter-spacing: 0px;
                        line-height: 1rem;

                        .red-dot {
                            color: #eb001b;
                            font-style: normal !important;
                        }
                    }

                    .span-title {
                        font-family: 'Mark Pro Bold';
                        font-size: 2rem;
                        color: #292c2d;
                        letter-spacing: 0px;
                        line-height: 1rem;

                        .red-dot {
                            color: #eb001b;
                            font-style: normal !important;
                        }
                    }

                }

                .title-center {
                    width: 100%;
                    padding: 10px 2% !important;
                    margin-bottom: 40px;
                    text-align: center;

                    .title-secondary-dark {
                        display: block;
                        font-family: 'Mark Pro Light';
                        font-size: 1em;
                        color: #ffffff;
                        letter-spacing: 0px;
                    }

                    .title-secondary-light {
                        display: block;
                        font-family: 'Mark Pro Light';
                        font-size: 1em;
                        color: #292c2d;
                        letter-spacing: 0px;
                    }

                    .span-title {
                        font-family: 'Mark Pro Bold';
                        font-size: 2rem;
                        color: #292c2d;
                        letter-spacing: 0px;
                        line-height: 2rem;

                        .red-dot {
                            color: #eb001b;
                            font-style: normal !important;
                        }
                    }
                }




            }

            .page-about {

                .pages {

                    .about-bg {

                        p {
                            text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.8), 1px -1px 0 rgba(255, 255, 255, 0.8), -1px 1px 0 rgba(255, 255, 255, 0.8), 1px 1px 0 rgba(255, 255, 255, 0.8);
                        }

                        .slogan {
                            padding-left: 5%;
                            width: 100%;
                            padding-bottom: 40px;
                            text-align: center;
    
                            h2 {
                                font-family: 'Mark Pro Bold';
                                font-size: 3rem;
                                color: #eb001b;
                                line-height: 1rem;
                            }
    
                            h3 {
                                font-family: 'Mark Pro Medium';
                                font-size: 3rem;
                                color: #292c2d;
                            }
                        }

                        .card-about {
                            position: relative;
                            width: 300px;
                            height: 450px;
                            background-color: #ffffff;
                            margin-bottom: 40px;

                            img {
                                width: 100%;
                                display: block;
                                height: auto;
                            }

                            .primary-content {
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                align-items: center;
                            }

                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                padding: 30px !important;
                                background-color: #292c2d;
                                opacity: 0;
                                height: 100%;
                                width: 100%;
                                transition: 0.5s ease;
                                text-align: center;
                                align-items: center;

                                .icons {
                                    width: 50px;
                                    height: 50px;
                                    display: block;
                                    margin: 0 auto;
                                }

                                .title {
                                    display: block;
                                    font-family: 'Mark Pro Bold';
                                    font-size: 1rem;
                                    color: #ff5f00;
                                    text-align: center;
                                    line-height: 1.5rem;
                                    margin-bottom: 40px;
                                }

                                .subtitle {
                                    display: block;
                                    font-family: 'Mark Pro Bold';
                                    font-size: 0.7rem;
                                    color: #ffffff;
                                    text-align: left;
                                }

                                .desc {
                                    font-family: 'Mark Pro';
                                    font-size: 0.7rem;
                                    color: #ffffff;
                                    text-align: left;
                                    text-shadow: none;
                                }

                                .btn-read-more {
                                    border: 0 !important;
                                    border-radius: 0 !important;
                                    background-color: #ff5f00;
                                    padding: 10px 30px;
                                    font-size: 0.8rem;
                                    color: #292c2d;

                                    &:hover {
                                        background-color: #eb001b;
                                        color: #ffffff;
                                    }
                                }
                            }

                            &:hover {
                                .overlay {
                                    opacity: 0.9;
                                }
                            }


                        }
                    }

                    .statement-section {
                        width: 100%;
                        height: auto!important;
                        margin: 0!important;
                        

                        .mission {
                            position: relative;
                            width: 100%!important;
                            //min-height: 1200px !important;
                            min-height: 800px!important;
                            display: flex;
                            flex-direction: column;


                            .item-left {
                                height: 400px!important;
                                width:100%!important;
                                padding: 20px;
                                flex-grow: 1;

                                .img-holder {
                                    height: 500px;
                                   
                                }
                                .overlay {
                                    height: 400px!important;
                                }
                            }
    
                            .item-center {
                                width: 100%;
                                height: 400px;
                                padding: 20px;
                                flex-grow: 1;

                                .img-mission {
                                    width: 100px;
                                }
    
                                .overlay {
                                    width: 100%;
                                    height: 500px;
                                    padding: 20px;
                                    
                                    .img-overlay {
                                        width: 40px;
                                    }
    
                                    h5 {
                                        font-size: 1.2rem;
                                        font-weight: 200;
                                    }
    
                                    .desc {
                                        font-size: 0.8rem;
                                    }
        
                                    .btn-read-more {
                                        background-color: #000000;
                                        padding: 10px 20px;
                                        font-size: 0.6rem;
                                        width: 80%;
                                        
                                    }
                                }
    
                            }

                            .item-right {
                                display: none!important;
                            }
    
                            /* .item-right {
                                width: 100%;
                                height: 400px;
                                padding: 20px;
                                flex-grow: 1;

                                .img-holder {
                                    height: 400px;
                                }
    
                                .overlay {
                                    height: 400px!important;
                                }
                            } */
    
                        }
    
                        .values {
                            position: relative;
                            width: 100%!important;
                            //min-height: 1200px !important;
                            min-height: 800px!important;
                            display: flex;
                            flex-direction: column;
                           
    
                            .item-left {
                                width: 100%!important;
                                height: 400px;
                                padding: 20px;
                                flex-grow: 1;

                                .img-holder {
                                    width: 100%;
                                    height: 400px;
                                    background-image: url('../img/cityscape.jpg');
                                }
    
                                .overlay {
                                    width: 100%;
                                    opacity: 0.8;
                                    height: 400px!important;
                                    
                                }
    
                                
                            }

                            .item-center {
                                width: 100%;
                                height: 400px;
                                padding: 20px;
                                flex-grow: 1;

                                .img-values {
                                    width: 100px;
                                }
    
                                .overlay {
                                    width: 100%;
                                    min-height: 400px;
                                    padding: 20px;
                                   
                                    .img-overlay {
                                        display: block;
                                        margin: 0 auto !important;
                                        width: 40px;
                                        
                                    }
    
                                    h5 {
                                        font-size: 1.2rem;
                                        font-weight: 200;
                                       
                                    }
    
                                    ul {
                                        li {
                                            font-size: 0.8rem;
                                            color: #ffffff;
                                        }
                                    }
        
                                    .btn-read-more {
                                        padding: 10px 20px;
                                        font-size: 0.6rem;
                                         width: 80%;
                                        &:hover {
                                            background-color: $primary;
                                            color: #ffffff;
                                        }
                                    }
                                }
    
    
    
                            }

                            .item-right {
                                display: none!important;
                            }

                          /*   .item-right {
                                width: 100%!important;
                                height: 400px;
                                padding: 20px;
                                flex-grow: 1;

                                .img-holder {
                                    width: 100%;
                                    height: 400px;
                                    background-image: url('../img/water-front.png');
                                }
    
                                .overlay {
                                    width: 100%;
                                    opacity: 0.8;
                                    height: 400px!important;
                                    
                                }
                                
                            } */
                        }
    
                        .vision {
                            position: relative;
                            width: 100%!important;
                            //min-height: 1200px !important;
                            min-height: 800px!important;
                            display: flex;
                            flex-direction: column;
                            
                            .item-left {
                                width: 100%!important;
                                height: 400px!important;
                                padding: 20px;
                               
                                .img-holder {
                                    width: 100%!important;
                                    height: 400px!important;
                                }
    
                                .overlay {
                                    width: 100%!important;
                                    height: 400px!important;
                                }
    
                               
                            }
    
                            .item-center {
                                width: 100%;
                                height: 400px!important;
                                padding: 20px;
                              
                                .img-vision {
                                    width: 100px;
                                }
    
                                .overlay {
                                    width: 100%;
                                    height: 400px!important;
                                    padding: 20px!important;
                                    
                                    .img-overlay {
                                        width: 40px!important;
                                    }
    
                                    h5 {
                                        font-size: 1.2rem;
                                        font-weight: 200;
                                    }
    
                                    .desc {
                                        font-size: 0.8rem;
                                       
                                    }
        
                                    .btn-read-more {
                                        padding: 10px 20px;
                                        font-size: 0.6rem;
                                        width: 80%;
                                        
                                    }
                                }
    
                            }

                            .item-right {
                                display: none!important;
                            }
    
                          /*   .item-right {
                                width: 100%!important;
                                height: 400px!important;
                                padding: 20px;
                             
                                .img-holder {
                                    width: 100%!important;
                                    height: 400px!important;
                                
                                }
    
                                .overlay {
                                    width: 100%!important;
                                    height: 400px!important;
                                }
    
                               
                            } */
                        }
                        
    
                    }
                }
            }

            

            .page-projects {

                .dark-page {

                    .project-container {
                        position: relative;
                        width: 100%;
                        min-height: 360px;
                        height: auto;
                        padding: 20px 10px;
    
                        .card {
                            height: 250px;
                            width: 250px;
                            cursor: pointer;
                            background-color: $black;
                            border: 0;
                            display: block;
                            left: 50%;
                            transform: translateX(-50%);
                            box-shadow: 0 0 5px rgba(black, 0.3);
                            overflow: hidden;
                            
                            .title-bar {
                                position: absolute;
                                bottom: 0!important;
                                width: 100%;
                                height: 40px;
                                text-align: center;
                                text-transform: uppercase;
                                opacity: 1;
                                background-color: $black;
    
                                .title {
                                    position: absolute;
                                    width: 90%!important;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    color: var(--color-white);
                                    text-align: center;
                                    display: block;
                                    font-size: 0.6rem!important;
    
                                }
                            }
    
                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                width: 250px;
                                height: 250px;
                                background-color: $black;
                                opacity: 0;
                                transition: all 0.5s ease-in-out;
    
                                .desc-container {
                                    position: absolute;
                                    width: 80%;
                                    height: auto;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%,-50%);
    
                                    .title {
                                        font-family: 'Mark Pro Medium';
                                        font-size: 0.9rem;
                                        color: var(--color-white);
                                        display: block;
                                        text-align: center;
                                        text-transform: uppercase;
                                        margin-bottom: 30px;
                                    }
                                    .desc {
                                        color: var(--color-white);
                                        display: block;
                                        font-family: 'Mark Pro';
                                        font-size: 0.6rem;
                                        text-align: center;
                                    }
                                    
                                }
                            }
    
                            .img-container {
                                position: absolute;
                                width: 250px;
                                height: 250px;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                transition: 1s ease-in-out;
                            }
    
                            &:hover {
    
                                .img-container {
                                    transform: scale(1.2);
                                }
    
                                .title-bar{ 
                                    opacity: 0;
                                }
    
                                .overlay {
                                    opacity: 0.9;
                                }
    
                            }
                        }
                    }
                }

                .addon-page-light {

                    .counter-stats {
                        width: 60%;
                        display: block;
                        justify-content: center;
                        margin: 20px auto !important;
                        cursor: pointer;

                        .stats {
                            width: 100%!important;
                            padding-bottom: 50px!important;
                        }
                    }
                }
            }

            .page-contacts {
                position: relative;
                width: 100%;
                height: auto;
                min-height: 100vh!important;
                margin: 0 !important;
            
                .pages {
                    position: relative;
                    min-height: 100vh!important;
                    margin: 0 !important;
    
                    .contacts-bg {
                        width: 100%;
                        height: auto;
                        min-height: 400px;
                        background-image: url('../img/contact-bg.jpg');
                        background-repeat: no-repeat;
                        background-size: cover;
    
                        .title-block {
                            width: 100%;
                            height: auto;
    
                            .subheading {
                                display: block;
                                color: #ffffff;
                                font-family: 'Mark Pro';
                                font-size: 1rem;
                            }
    
                            .title {
                                font-family: 'Mark Pro Bold';
                                font-size: 1.5rem;
                                color:#ff5f00;
                                line-height: 1rem;
                            }
                        }
                    }
    
                    .map-container {
                        position: absolute;
                        top: 150px;
                        width: 90%;
                        height: 600px;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 20px;
                        background-color: #ffffff;
                        box-shadow: 0 0 20px rgba(black, 0.3);
    
                        .contact-header {
                            font-family: 'Mark Pro Medium';
                            font-size: 1rem;
                            font-weight: 300;
                            display: block;
                            padding-bottom: 20px;
                            text-transform: uppercase;
                            text-align: center;
                            
                        }
    
                        .map {
                            margin-top: 20px;
                            width: 100%!important;
                            height: 100%!important;
                        }
                    }
                }
    
            }
    

        }

        .footer-wrapper {
            position: relative;
            width: 100%;
            margin: 0 !important;
            height: auto;
            background-color: #ffffff;
    
            .address-container {
                width: 100%;
                padding: 50px auto !important;
                text-align: center;
    
                .address-header {
                    display: block !important;
                    font-family: 'Mark Pro';
                    font-size: 1rem;
                    color: $primary !important;
                }

                p {
                    font-size: 0.8rem;
                }
            }
    
            .socmed-container {
                width: 100%;
                align-items: center;
                text-align: center;
                padding: 50px auto!important;
    
                .foot-title {
                    font-size: 0.8rem;
                }
    
                .socmed-holder {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    justify-content: center;
                    margin-bottom: 30px;
                    .icons {
                        width: 20px;
                        height: 20px;
                        margin: 5px;
                        border-radius: 50%;
                        background-color: #292c2d;
                        text-decoration: none;
                        transition: all 0.5s ease;
    
                        img {
                            display: block;
                            width: 100%;
                        }
    
                        &:hover {
                            background-color: $primary;
                        }
                    }
    
                }
    
            }
    
            .foot-note {
                width: 100%;
                height: 80px;
                padding: 5px 15px;
                background-color: #000000;
                display: flex;
                justify-content: center;
                flex-direction: column;
                color: #ffffff;
                 
                .foot-logo {
                    display: block;
                    height: 10px!important;
                    padding: 10px;
                    margin: 0 auto !important;
                }
    
                .company-name {
                    font-family: 'Mark Pro';
                    font-size: 0.6rem;
                    color: #ffffff;
                    font-weight: 100;
                    letter-spacing: 1px;
                    display: block;
                    width: 100%;
                    text-align: center;
    
                }
    
                .policy {
                    width: auto!important;
                    text-align: center;
                    height: 10px;
                    display: block;
    
                    a {
                        
                        font-weight: 100;
                        font-size: 0.6rem;
                        letter-spacing: 1px;
                        color: #ffffff;
                        text-decoration: none;
                        cursor: pointer;
    
                        &:hover {
                            color:#ff5f00;
                        }
                    }
                }
    
    
            }
    
        }

    }
}

@keyframes prev {
    from {
        transform: translateX(0px);
    } 
    to {
        transform: translateX(calc(0px - var(--cardWidth)));
    }
}

@keyframes next {
    from {
        transform: translateX(0px);
    } 
    to {
        transform: translateX(calc(0px + var(--cardWidth)));
    }
}

@keyframes enlarge {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.8);
    }
    
}
